var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vx-card',{staticClass:"justify-center"},[_c('vs-row',[_c('vs-col',{staticClass:"flex justify-center",attrs:{"vs-w":"12"}},[_c('div',{staticClass:"flex flex-wrap justify-center"},[_c('h3',{staticClass:"flex w-full justify-center"},[_vm._v("Back-end Core")]),_c('p',{staticClass:"flex w-full my-5 justify-center"},[_vm._v(" بار کلی: "+_vm._s(_vm.backendData.phpload)+" ")])])]),_c('vs-col',{staticClass:"flex justify-center",attrs:{"vs-w":"4","vs-sm":"12","vs-xs":"12"}},[_c('vx-card',[_c('div',{staticClass:"w-full flex flex-wrap justify-center"},[_c('vue-ellipse-progress',{staticClass:"w-full",attrs:{"progress":_vm.backendData.cpu_load,"angle":-90,"color":_vm.backendData.cpu_load <= 35
                  ? '#28C76F'
                  : _vm.backendData.cpu_load <= 70
                  ? '#FF9F00'
                  : _vm.backendData.cpu_load <= 80
                  ? '#F22F22'
                  : '#32010E',"emptyColor":"#333","emptyColorFill":"#00f1","size":130,"thickness":10,"animation":"default 0 500","noData":false,"fontColor":"white","fontSize":"2rem"}},[_c('span',{attrs:{"slot":"legend-value"},slot:"legend-value"},[_vm._v("%")]),_c('p',{attrs:{"slot":"legend-caption"},slot:"legend-caption"},[_vm._v("CPU load")])]),(_vm.backendData.cpu_load >= 80)?_c('div',{staticClass:"flex my-2"},[_c('vs-icon',{staticClass:"blink_me text-danger",attrs:{"size":"small","icon":"warning"}}),(_vm.backendData.cpu_load >= 87)?_c('p',[_vm._v("هشدار مصرف پردازنده")]):_vm._e()],1):_vm._e()],1),_c('vs-row',{staticClass:"flex justify-center"},[_c('h4',{staticClass:"w-full"},[_vm._v("پردازنده")]),_c('vs-col',{staticClass:"w-full flex justify-between",attrs:{"vs-w":"12"}},[_c('p',[_vm._v("کل هسته ها:")]),_c('p',[_vm._v(_vm._s(_vm.backendData.cpu_count))])])],1)],1)],1),_c('vs-col',{staticClass:"flex justify-center",attrs:{"vs-w":"4","vs-sm":"6","vs-xs":"12"}},[_c('vx-card',[_c('div',{staticClass:"w-full flex flex-wrap justify-center"},[_c('vue-ellipse-progress',{staticClass:"mx-5 w-full",attrs:{"progress":_vm.backendData.disk_usage,"angle":-90,"color":_vm.backendData.disk_usage <= 35
                  ? '#28C76F'
                  : _vm.backendData.disk_usage <= 70
                  ? '#FF9F00'
                  : _vm.backendData.disk_usage <= 80
                  ? '#F22F22'
                  : '#32010E',"emptyColor":"#333","emptyColorFill":"#00f1","size":130,"thickness":10,"animation":"default 0 500","noData":false,"fontColor":"white","fontSize":"2rem"}},[_c('span',{attrs:{"slot":"legend-value"},slot:"legend-value"},[_vm._v("%")]),_c('p',{attrs:{"slot":"legend-caption"},slot:"legend-caption"},[_vm._v("Hard used")])]),(_vm.backendData.disk_usage >= 80)?_c('div',{staticClass:"flex my-2"},[_c('vs-icon',{staticClass:"blink_me text-danger",attrs:{"size":"small","icon":"warning"}}),(_vm.backendData.disk_usage >= 87)?_c('p',[_vm._v("هشدار مصرف هارد")]):_vm._e()],1):_vm._e()],1),_c('vs-row',{staticClass:"flex justify-center"},[_c('h4',{staticClass:"w-full"},[_vm._v("اطلاعات هارد")]),_c('vs-col',{staticClass:"w-full flex justify-between",attrs:{"vs-w":"12"}},[_c('p',[_vm._v("کل :")]),_c('p',[_vm._v(_vm._s(_vm.backendData.disk_total))])]),_c('vs-col',{staticClass:"w-full flex justify-between",attrs:{"vs-w":"12"}},[_c('p',[_vm._v("مصرف شده :")]),_c('p',[_vm._v(_vm._s(_vm.backendData.disk_used))])])],1)],1)],1),_c('vs-col',{staticClass:"flex justify-center",attrs:{"vs-w":"4","vs-sm":"6","vs-xs":"12"}},[_c('vx-card',[_c('div',{staticClass:"w-full flex flex-wrap justify-center"},[_c('vue-ellipse-progress',{staticClass:"mx-5 w-full",attrs:{"progress":Math.round(_vm.backendData.mem_usage * 100) / 100,"angle":-90,"color":_vm.backendData.mem_usage <= 35
                  ? '#28C76F'
                  : _vm.backendData.mem_usage <= 70
                  ? '#FF9F00'
                  : _vm.backendData.mem_usage <= 80
                  ? '#F22F22'
                  : '#32010E',"emptyColor":"#333","emptyColorFill":"#00f1","size":130,"thickness":10,"animation":"default 0 500","noData":false,"fontColor":"white","fontSize":"2rem"}},[_c('span',{attrs:{"slot":"legend-value"},slot:"legend-value"},[_vm._v("%")]),_c('p',{attrs:{"slot":"legend-caption"},slot:"legend-caption"},[_vm._v("RAM used")])]),(_vm.backendData.mem_usage >= 75)?_c('div',{staticClass:"flex my-2"},[_c('vs-icon',{staticClass:"blink_me text-danger",attrs:{"size":"small","icon":"warning"}}),(_vm.backendData.mem_usage >= 85)?_c('p',[_vm._v("هشدار مصرف RAM")]):_vm._e()],1):_vm._e()],1),_c('vs-row',{staticClass:"flex justify-center"},[_c('h4',{staticClass:"w-full"},[_vm._v("اطلاعات RAM")]),_c('vs-col',{staticClass:"w-full flex justify-between",attrs:{"vs-w":"12"}},[_c('p',[_vm._v("کل :")]),_c('p',[_vm._v(_vm._s(_vm.backendData.mem_total))])]),_c('vs-col',{staticClass:"w-full flex justify-between",attrs:{"vs-w":"12"}},[_c('p',[_vm._v("مصرف شده :")]),_c('p',[_vm._v(_vm._s(_vm.backendData.mem_used))])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }