<template>
  <div>
    <vx-card class="justify-center">
      <vs-row>
        <vs-col class="flex justify-center" vs-w="12">
          <div class="flex flex-wrap justify-center">
            <h3 class="flex w-full justify-center">Back-end Core</h3>
            <p class="flex w-full my-5 justify-center">
              بار کلی: {{ backendData.phpload }}
            </p>
          </div>
        </vs-col>
        <vs-col class="flex justify-center" vs-w="4" vs-sm="12" vs-xs="12">
          <vx-card>
            <div class="w-full flex flex-wrap justify-center">
              <vue-ellipse-progress
                class="w-full"
                :progress="backendData.cpu_load"
                :angle="-90"
                :color="
                  backendData.cpu_load <= 35
                    ? '#28C76F'
                    : backendData.cpu_load <= 70
                    ? '#FF9F00'
                    : backendData.cpu_load <= 80
                    ? '#F22F22'
                    : '#32010E'
                "
                emptyColor="#333"
                emptyColorFill="#00f1"
                :size="130"
                :thickness="10"
                animation="default 0 500"
                :noData="false"
                fontColor="white"
                fontSize="2rem"
              >
                <span slot="legend-value">%</span>
                <p slot="legend-caption">CPU load</p>
              </vue-ellipse-progress>
              <div v-if="backendData.cpu_load >= 80" class="flex my-2">
                <vs-icon
                  size="small"
                  class="blink_me text-danger"
                  icon="warning"
                ></vs-icon>
                <p v-if="backendData.cpu_load >= 87">هشدار مصرف پردازنده</p>
              </div>
            </div>
            <vs-row class="flex justify-center">
              <h4 class="w-full">پردازنده</h4>
              <vs-col class="w-full flex justify-between" vs-w="12"
                ><p>کل هسته ها:</p>
                <p>{{ backendData.cpu_count }}</p></vs-col
              >
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col class="flex justify-center" vs-w="4" vs-sm="6" vs-xs="12">
          <vx-card>
            <div class="w-full flex flex-wrap justify-center">
              <vue-ellipse-progress
                class="mx-5 w-full"
                :progress="backendData.disk_usage"
                :angle="-90"
                :color="
                  backendData.disk_usage <= 35
                    ? '#28C76F'
                    : backendData.disk_usage <= 70
                    ? '#FF9F00'
                    : backendData.disk_usage <= 80
                    ? '#F22F22'
                    : '#32010E'
                "
                emptyColor="#333"
                emptyColorFill="#00f1"
                :size="130"
                :thickness="10"
                animation="default 0 500"
                :noData="false"
                fontColor="white"
                fontSize="2rem"
              >
                <span slot="legend-value">%</span>
                <p slot="legend-caption">Hard used</p>
              </vue-ellipse-progress>
              <div v-if="backendData.disk_usage >= 80" class="flex my-2">
                <vs-icon
                  size="small"
                  class="blink_me text-danger"
                  icon="warning"
                ></vs-icon>
                <p v-if="backendData.disk_usage >= 87">هشدار مصرف هارد</p>
              </div>
            </div>
            <vs-row class="flex justify-center">
              <h4 class="w-full">اطلاعات هارد</h4>
              <vs-col class="w-full flex justify-between" vs-w="12"
                ><p>کل :</p>
                <p>{{ backendData.disk_total }}</p></vs-col
              >
              <vs-col class="w-full flex justify-between" vs-w="12"
                ><p>مصرف شده :</p>
                <p>{{ backendData.disk_used }}</p></vs-col
              >
            </vs-row>
            <!-- <div>{{ backendData }}</div> -->
          </vx-card>
        </vs-col>
        <vs-col class="flex justify-center" vs-w="4" vs-sm="6" vs-xs="12">
          <vx-card>
            <div class="w-full flex flex-wrap justify-center">
              <vue-ellipse-progress
                class="mx-5 w-full"
                :progress="Math.round(backendData.mem_usage * 100) / 100"
                :angle="-90"
                :color="
                  backendData.mem_usage <= 35
                    ? '#28C76F'
                    : backendData.mem_usage <= 70
                    ? '#FF9F00'
                    : backendData.mem_usage <= 80
                    ? '#F22F22'
                    : '#32010E'
                "
                emptyColor="#333"
                emptyColorFill="#00f1"
                :size="130"
                :thickness="10"
                animation="default 0 500"
                :noData="false"
                fontColor="white"
                fontSize="2rem"
              >
                <span slot="legend-value">%</span>
                <p slot="legend-caption">RAM used</p>
              </vue-ellipse-progress>
              <div v-if="backendData.mem_usage >= 75" class="flex my-2">
                <vs-icon
                  size="small"
                  class="blink_me text-danger"
                  icon="warning"
                ></vs-icon>
                <p v-if="backendData.mem_usage >= 85">هشدار مصرف RAM</p>
              </div>
            </div>
            <vs-row class="flex justify-center">
              <h4 class="w-full">اطلاعات RAM</h4>
              <vs-col class="w-full flex justify-between" vs-w="12"
                ><p>کل :</p>
                <p>{{ backendData.mem_total }}</p></vs-col
              >
              <vs-col class="w-full flex justify-between" vs-w="12"
                ><p>مصرف شده :</p>
                <p>{{ backendData.mem_used }}</p></vs-col
              >
            </vs-row>
            <!-- <div>{{ backendData }}</div> -->
          </vx-card>
        </vs-col>
      </vs-row>
    </vx-card>
    <!-- {{ backendData }} -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    backendData: { required: true },
  },
};
</script>

<style>
.blink_me {
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
</style>