<template>
  <div>
    <div>
      <BackendCore :backendData="backendData" />
    </div>
  </div>
</template>

<script>
import BackendCore from "./components/backendCore.vue";
export default {
  components: {
    BackendCore,
  },
  data() {
    return {
      backendData: "",
      // backendData : {mem_usage : 85, disk_usage : 89, cpu_load: 90}
    };
  },
  methods: {
    getBackendData() {
      this.$http
        .get("/admin/health/check")
        .then((res) => {
          console.log(res);
          this.backendData = res.data.status;
          this.backendData["mem_usage"] =
            (this.backendData.mem_used * this.backendData.mem_total) / 100;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getBackendData();
  },
};
</script>

<style>
</style>